import React, { FC } from "react"

import { Button } from "@launchacademy/voyager"

import { CourseScheduleProps } from "./CourseSchedule.d"

export const CourseSchedule: FC<CourseScheduleProps> = ({
  name,
  enrollmentEnds,
  preflightBegins,
  ignitionBegins,
  cohortBegins,
  cohortEnds,
  buttonURL,
  buttonText,
}) => {
  const endDateArray = enrollmentEnds.replace(",", "").split(" ")
  const endDateObject = new Date(Date.parse(endDateArray[1] + endDateArray[0] + endDateArray[2]))
  const enrollmentIsOpen = endDateObject.getTime() >= new Date().getTime()
  const isImmersiveOnsite = preflightBegins && ignitionBegins

  return (
    <div
      className={`course-schedule ${
        isImmersiveOnsite
          ? "course-schedule__immersive-onsite"
          : "course-schedule__part-time-online"
      }`}
    >
      <div className="course-schedule__course-content course-schedule__course-content_two-column">
        <label className="course-schedule__label">Cohort</label>
        <h3 className="course-schedule__cohort">{name}</h3>
      </div>
      <div className="course-schedule__course-content course-schedule__course-content_two-column">
        <label className="course-schedule__label">Enrollment ends</label>
        <h4 className="course-schedule__date">{enrollmentEnds}</h4>
      </div>
      {preflightBegins && (
        <div className="course-schedule__course-content">
          <label className="course-schedule__label">Preflight begins</label>
          <h4 className="course-schedule__date">{preflightBegins}</h4>
        </div>
      )}
      {ignitionBegins && (
        <div className="course-schedule__course-content">
          <label className="course-schedule__label">Ignition begins</label>
          <h4 className="course-schedule__date">{ignitionBegins}</h4>
        </div>
      )}
      <div className="course-schedule__course-content">
        <label className="course-schedule__label">Cohort begins</label>
        <h4 className="course-schedule__date">{cohortBegins}</h4>
      </div>
      <div className="course-schedule__course-content">
        <label className="course-schedule__label">Cohort ends</label>
        <h4 className="course-schedule__date">{cohortEnds}</h4>
      </div>
      {enrollmentIsOpen && (
        <div className="course-schedule__course-content course-schedule__course-content_two-column">
          <Button to={buttonURL} text={buttonText} className="hero__button" size="md" />
        </div>
      )}
    </div>
  )
}
