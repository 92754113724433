import React, { FC, Fragment } from "react"

import format from "date-fns/format"

import { CourseSchedule } from "./CourseSchedule"
import { CourseScheduleListProps } from "./CourseSchedule.d"

export const CourseScheduleList: FC<CourseScheduleListProps> = ({ cohortSchedule }) => {
  let enrollmentEnds
  let preflightBegins
  let ignitionBegins
  let cohortBegins
  let cohortEnds

  const cohortScheduleData = cohortSchedule.map((date) => {
    enrollmentEnds = format(new Date(date.enrollmentEnds), "MMM dd, yyyy")
    preflightBegins = date.preflightBegins && format(new Date(date.preflightBegins), "MMM dd, yyyy")
    ignitionBegins = date.ignitionBegins && format(new Date(date.ignitionBegins), "MMM dd, yyyy")
    cohortBegins = format(new Date(date.cohortBegins), "MMM dd, yyyy")
    cohortEnds = format(new Date(date.cohortEnds), "MMM dd, yyyy")

    return (
      <CourseSchedule
        key={date.name}
        name={date.name}
        enrollmentEnds={enrollmentEnds}
        preflightBegins={preflightBegins}
        ignitionBegins={ignitionBegins}
        cohortBegins={cohortBegins}
        cohortEnds={cohortEnds}
        buttonURL={date.buttonURL}
        buttonText={date.buttonText}
      />
    )
  })

  return <Fragment>{cohortScheduleData}</Fragment>
}
