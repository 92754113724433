import React from "react"

import {
  faBaby,
  faCheckCircle,
  faChevronRight,
  faCircle,
  faEye,
  faInfoCircle,
  faPhone,
  faPlay,
  faRunning,
  faStar,
  faTimesCircle,
  faUserGraduate,
  faWalking,
} from "@fortawesome/free-solid-svg-icons"
import {
  Button,
  SimpleBanner,
  BannerTitle,
  BannerContent,
  TitledParagraph,
  Profile,
  CreativeContent,
  MobileDeviceTile,
  Icon,
  Quote,
} from "@launchacademy/voyager"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Configuration from "../Configuration"
import { CourseScheduleList } from "../components/courseSchedule/CourseScheduleList"
import { Fact } from "../components/factSet/Fact"
import { FactSet } from "../components/factSet/FactSet"
import { FeatureList } from "../components/featureList/FeatureList"
import { FeatureListItem } from "../components/featureList/FeatureListItem"
import BookInfoCallButton from "../components/infoCall/BookInfoCallButton"
import Layout from "../components/layout"
import { PartnerLogoList } from "../components/partnerLogoList/PartnerLogoList"
import Seo from "../components/seo"
import SyllabusModalButton from "../components/syllabus/SyllabusModalButton.js"
import VideoViewButton from "../components/videoModal/VideoViewButton"
import { cohortSchedule } from "../pageData/programs/immersiveOnsitePageData"
import { buildCohortProps } from "../services/buildCohortProps"
import buildImage from "../services/buildImage.js"

import "./css/index.css"
import "../css/accent-images/index.css"

const IndexPage = ({ data, location }) => {
  const hiringPartnerData = data.hiringPartnerSet.featured_hiring_partners.map((partner) => {
    return {
      name: partner.companyName,
      logoImage: <GatsbyImage image={getImage(partner.logo.localFile)} alt={partner.companyName} />,
    }
  })
  const featuredProfiles = data.launcherSet.featured_launchers.map((launcher) => {
    return (
      <Profile
        key={launcher.id}
        name={`${launcher.firstName} ${launcher.lastName}`}
        Image={buildImage(launcher.headshot?.localFile)}
        altText={`${launcher.firstName} ${launcher.lastName}`}
        currentTitle={launcher.currentJobTitle}
        currentEmployer={launcher.currentEmployer}
      />
    )
  })

  const cohorts = buildCohortProps(data.cohorts.nodes)
  const virtualCohorts = buildCohortProps(data.virtualCohorts.nodes)

  return (
    <Layout>
      <Seo
        title="Launch Academy: A Boston coding bootcamp and code school in Massachusetts."
        pathname={location.pathname}
        description="A Boston coding bootcamp specializing in Ruby, Rails, JavaScript, and ReactJS. Become a software developer through our 18 week immersive program."
      />
      <section className="hero">
        <div className="hero__image-column">
          <div className="hero__image-container">
            <GatsbyImage
              image={getImage(data.headerImage)}
              className="hero__image hero__image_rounded"
              alt="Classroom study"
            />
            <VideoViewButton
              videoId="KwDz4sQYzk8"
              videoProvider="youtube"
              hideText
              size="lg"
              text="Play Video"
              icon={faPlay}
              className="hero__play-button"
            />
          </div>
        </div>
        <div className="hero__supporting-content">
          <h2 className="hero__image_subtitle">Going Pro Starts Here.</h2>
          <h1 className="hero__image_title">Launch your career in software today.</h1>
          <div className="hero__button-container">
            <BookInfoCallButton
              text="Schedule an Info Call"
              icon={faPhone}
              className="hero__button pr-3"
              location={location}
              size="lg"
            />
          </div>
          <div className="hero__button-container">
            <VideoViewButton
              videoId="KwDz4sQYzk8"
              videoProvider="youtube"
              icon={faEye}
              text="Watch Video"
              secondary
              className="hero__button"
              size="lg"
            />
          </div>
        </div>
      </section>
      <SimpleBanner BackgroundImage={buildImage(data.standup)}>
        <BannerTitle>See us in action</BannerTitle>

        <VideoViewButton
          videoId="KwDz4sQYzk8"
          videoProvider="youtube"
          icon={faEye}
          text="Watch Video"
          secondary
          size="lg"
          placement="photo"
        />
      </SimpleBanner>
      <TitledParagraph title="A proven path to success for aspiring software engineers">
        <p>
          Launch Academy gives you everything you need to succeed as a professional software
          engineer, driven by the practical skills employers are looking for.
        </p>
        <FactSet>
          <Fact value="1000+" label="Alumni" />
          <Fact
            value={
              <>
                <Icon icon={faStar} size="lg" />
                4.6
              </>
            }
            label="CourseReport Rating"
          />
          <Fact value="$68.7k" label="Avg Salary" />
        </FactSet>
      </TitledParagraph>
      <div className="profile-list lg:w-3/4 lg:mx-auto">{featuredProfiles}</div>
      <div className="constrained-container">
        <TitledParagraph title="Launch graduates get hired">
          <p>
            Our grads find long-term career success at some of the most selective and in-demand
            employers in the industry.
          </p>
        </TitledParagraph>
        <PartnerLogoList className="mb-20" partnerData={hiringPartnerData} />
      </div>

      <SimpleBanner BackgroundImage={buildImage(data.collaboratingBackground)}>
        <div className="container">
          <BannerTitle>
            A practical computer science education without the bloat of a 4-year program
          </BannerTitle>
          <BannerContent>
            <p>
              Launch provides a highly-structured, collaborative learning environment designed to
              prepare you for your new career in just 18 weeks.
            </p>
          </BannerContent>
        </div>
      </SimpleBanner>

      <div className="phase-list">
        <div className="phase-list__item">
          <div className="phase-list__item_icon">
            <Icon icon={faBaby} aria-hidden="true" fixedWidth={true} />
          </div>
          <div className="phase-list__title">Course Prep &amp; Pre-work</div>
          <div className="phase-list__subtitle">ONLINE | PART-TIME | 3 WEEKS</div>
          <div className="phase-list__text">
            Get started with the basics of coding during this optional course prep
          </div>
          <p>
            <strong>Unit 1:</strong> Validate Interest
          </p>
          <p>
            <strong>Unit 2:</strong> Prepare To Learn
          </p>
          <p>
            <strong>Unit 3:</strong> Introductory Concepts
          </p>
          <p>
            <strong>Unit 4:</strong> Reduce Imposter Syndrome
          </p>
          <p>
            <strong>Unit 5:</strong> Mitigate Risk
          </p>
          <p>
            <strong>Unit 6:</strong> Get Ready
          </p>
        </div>
        <div className="phase-list__item phase-list__item_blue">
          <div className="phase-list__item_icon">
            <Icon icon={faWalking} aria-hidden="true" fixedWidth={true} />
          </div>
          <div className="phase-list__title">Coding Fundamentals</div>
          <div className="phase-list__subtitle">ONLINE | PART-TIME | 8 WEEKS</div>
          <div className="phase-list__text">
            Build a strong foundation and get familiar with the tools of the trade
          </div>
          <ol>
            <li>
              <strong>Unit 1:</strong> Config &amp; Command Line
            </li>
            <li>
              <strong>Unit 2:</strong> Debugging &amp; Troubleshooting
            </li>
            <li>
              <strong>Unit 3:</strong> JavaScript, NodeJS
            </li>
            <li>
              <strong>Unit 4:</strong> Data Structures
            </li>
            <li>
              <strong>Unit 5:</strong> OOP &amp; Libraries
            </li>
            <li>
              <strong>Unit 6:</strong> PostgreSQL, HTML, CSS
            </li>
          </ol>
        </div>
        <div className="phase-list__item phase-list__item_blue">
          <div className="phase-list__item_icon">
            <Icon icon={faRunning} aria-hidden="true" fixedWidth={true} />
          </div>
          <div className="phase-list__title">Software Immersive</div>
          <div className="phase-list__subtitle">CAMPUS | FULL-TIME | 10 WEEKS</div>
          <div className="phase-list__text">
            Develop advanced-level skills in a hands-on environment
          </div>
          <ol>
            <li>
              <strong>Unit 1:</strong> Test-Driven Development
            </li>
            <li>
              <strong>Unit 2:</strong> ReactJS & API’s
            </li>
            <li>
              <strong>Unit 3:</strong> Full-Stack Apps
            </li>
            <li>
              <strong>Unit 4:</strong> Technical Interview Practice
            </li>
            <li>
              <strong>Unit 5:</strong> Team Project
            </li>
            <li>
              <strong>Unit 6:</strong> Capstone Project
            </li>
          </ol>
        </div>
        <div className="phase-list__item">
          <div className="phase-list__item_icon">
            <Icon icon={faUserGraduate} aria-hidden="true" fixedWidth={true} />
          </div>
          <div className="phase-list__title">Career Advancement</div>
          <div className="phase-list__subtitle">ONLINE | FOR YOUR ENTIRE CAREER</div>
          <div className="phase-list__text">
            Land your first job and get access to lifetime career services support
          </div>
          <ul style={{ listStyle: "'- ' inside" }}>
            <li>Pitch Practice</li>
            <li>Meet Local Companies</li>
            <li>Job Interview Prep</li>
            <li>Advanced Curriculum Releases</li>
            <li>Free Workshare Space</li>
            <li>Lifetime Career Support!</li>
          </ul>
        </div>
      </div>
      <TitledParagraph title="Looking for our Virtual Program?">
        <Button to="/programs/part-time-online">Program Details</Button>
      </TitledParagraph>
      <div className="banner-with-creative">
        <CreativeContent>
          <GatsbyImage image={getImage(data.cirrLogo)} alt="CIRR Logo" />
        </CreativeContent>
        <TitledParagraph title="We invest a lifetime in the success of our graduates. Accountability is one of our core tenets.">
          <p className="cirr-banner__paragraph">
            You deserve to make informed decisions about your future. CIRR is an independent
            standards body that provides transparent reporting on 100% of our outcomes.
          </p>
        </TitledParagraph>
      </div>

      <section className="comparison-table-container">
        <h3 className="comparison-table__table-title">How We Stack Up</h3>
        <table className="comparison-table">
          <thead className="comparison-table__header-container">
            <tr>
              <th className="header-container__header-title"></th>
              <th className="header-container__header-title header-container__header-title_launch">
                Launch Academy
              </th>
              <th className="header-container__header-title">CS Degree</th>
              <th className="header-container__header-title">Average Bootcamp</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="comparison-table__data-title">Applied Learning Hours</td>
              <td className="comparison-table__data-text comparison-table__data-text_launch">
                700+
              </td>
              <td className="comparison-table__data-text">800+</td>
              <td className="comparison-table__data-text">450+</td>
            </tr>
            <tr>
              <td className="comparison-table__data-title">Time commitment (weeks)</td>
              <td className="comparison-table__data-text comparison-table__data-text_launch">18</td>
              <td className="comparison-table__data-text">200+</td>
              <td className="comparison-table__data-text">12+</td>
            </tr>
            <tr>
              <td className="comparison-table__data-title">Real-time, market-driven curriculum</td>
              <td className="comparison-table__data-text comparison-table__data-text_yes">
                <Icon icon={faCheckCircle} />
              </td>
              <td className="comparison-table__data-text comparison-table__data-text_no">
                <Icon icon={faTimesCircle} />
              </td>
              <td className="comparison-table__data-text comparison-table__data-text_no">
                <Icon icon={faTimesCircle} />
              </td>
            </tr>
            <tr>
              <td className="comparison-table__data-title">Lifetime learning access</td>
              <td className="comparison-table__data-text comparison-table__data-text_yes">
                <Icon icon={faCheckCircle} />
              </td>
              <td className="comparison-table__data-text comparison-table__data-text_no">
                <Icon icon={faCheckCircle} />
              </td>
              <td className="comparison-table__data-text comparison-table__data-text_no">
                <Icon icon={faTimesCircle} />
              </td>
            </tr>
            <tr>
              <td className="comparison-table__data-title">Lifetime career support</td>
              <td className="comparison-table__data-text comparison-table__data-text_yes">
                <Icon icon={faCheckCircle} />
              </td>
              <td className="comparison-table__data-text comparison-table__data-text_yes">
                <Icon icon={faCheckCircle} />
              </td>
              <td className="comparison-table__data-text comparison-table__data-text_no">
                <Icon icon={faTimesCircle} />
              </td>
            </tr>
            <tr>
              <td className="comparison-table__data-title">Direct intro to hiring partners</td>
              <td className="comparison-table__data-text comparison-table__data-text_yes">
                <Icon icon={faCheckCircle} />
              </td>
              <td className="comparison-table__data-text comparison-table__data-text_no">
                <Icon icon={faTimesCircle} />
              </td>
              <td className="comparison-table__data-text comparison-table__data-text_no">
                <Icon icon={faTimesCircle} />
              </td>
            </tr>
            <tr>
              <td className="comparison-table__data-title">Total Investment</td>
              <td className="comparison-table__data-text comparison-table__data-text_launch">
                $16-$17k
              </td>
              <td className="comparison-table__data-text">$80-$200k</td>
              <td className="comparison-table__data-text">$8-$26k</td>
            </tr>
          </tbody>
        </table>
      </section>

      <div className="container">
        <Quote
          quoteType="banner"
          quote="The Ignition program they implement before coming on campus greatly sets them apart from other camps and will assist all newcomers in being able to digest such a large amount of information."
          name="Alex Smith"
        />
      </div>

      <section className="benefit-list relative">
        <FeatureList
          prefixContent={
            <>
              <GatsbyImage
                image={getImage(data.stuffedAnimals)}
                className="benefit-list__top-left-accent"
              />
              <GatsbyImage
                image={getImage(data.mockInterview)}
                className="benefit-list__top-right-accent"
              />
            </>
          }
          title="Cut through the noise.
        Launch your career the right way."
          footerText="We’re great at what we do"
        >
          <FeatureListItem
            title="Constantly evolving curriculum"
            subtitle="Learn the skills that matter in the job market today"
            description="The technology landscape changes almost daily, and so do the qualifications hiring managers are looking for in the market. That’s why we work directly with our hiring partners to ensure every graduating class is armed with the most up-to-date skillset possible and update our curriculum to reflect those needs in real-time."
          />
          <FeatureListItem
            title="Practical, hands-on learning"
            subtitle="Build your confidence by doing the real work"
            description="Our curriculum is designed to prepare you for success as a professional developer in the real world. We condense the most practical skills and practices of a 4-year CS degree into just 18 weeks in order to maximize your return on time spent working with real code. We make sure you get the chance to get your hands in the code and get messy, from your first day of coding on — with plenty of support along the way."
          />
          <FeatureListItem
            title="Full-time, dedicated teaching staff"
            subtitle="Learn from practicing experts who are committed to your success"
            description="Unlike other bootcamps, every member of the Launch team is a full-time employee. We have no other responsibilities or distractions besides teaching you what you need to be successful and ensuring you get there. We are all-in."
          />
          <FeatureListItem
            title="Lifetime learning access and support"
            subtitle="Stay ahead of the curve for the rest of your career"
            description="You’ll never have to worry about being out of the loop on current hiring trends and new technologies. Long after you graduate, you’ll still get access to updated course content as soon as it’s released and  have a direct line to career services for the rest of, well, forever."
          />
        </FeatureList>
      </section>

      <section className=" text-center py-20 container">
        <h2 className="text-center">Upcoming immersive course schedule</h2>
        <CourseScheduleList cohortSchedule={cohorts} />
      </section>
      <section className=" text-center pb-20 container">
        <h2 className="text-center">Upcoming virtual course schedule</h2>
        <CourseScheduleList cohortSchedule={virtualCohorts} />
      </section>

      <section className="banner_device">
        <div className="banner_device__container">
          <BannerContent>
            <div className="banner_device__title">Ready to design a better future?</div>
            <div className="banner_device__container">
              <div className="hero__button-container">
                <Button
                  to={Configuration.applicationBaseUrl}
                  text="Apply Today"
                  icon={faChevronRight}
                  className="button banner__button"
                  size="lg"
                />
              </div>
              <div className="hero__button-container">
                <SyllabusModalButton
                  size="lg"
                  location={location}
                  icon={faChevronRight}
                  className="banner__button button_secondary"
                />
              </div>
            </div>
          </BannerContent>
          <div className="banner_device__device-container">
            <CreativeContent>
              <div className="device-wrapper">
                <MobileDeviceTile Image={buildImage(data.iphoneScreenIndex)} />
              </div>

              <div className="device-wrapper">
                <div id="banner-accent-teaching-code" className="banner__background-accent">
                  <GatsbyImage image={getImage(data.nickTeaching)} alt="Instructor teaching code" />
                </div>
                <MobileDeviceTile Image={buildImage(data.iphoneScreenIndexSW)} />
              </div>
            </CreativeContent>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    headerImage: file(relativePath: { regex: "/index-page/headerImage.jpg/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          breakpoints: [210, 630]
          aspectRatio: 1
          blurredOptions: { toFormat: WEBP }
          placeholder: BLURRED
        )
      }
    }
    profileImageOne: file(relativePath: { regex: "/index-page/profileImageOne.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    profileImageTwo: file(relativePath: { regex: "/index-page/profileImageTwo.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    profileImageThree: file(relativePath: { regex: "/index-page/profileImageThree.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    cohorts: allStrapiCohort(
      sort: { order: ASC, fields: cohortBegins }
      filter: { showOnSite: { eq: true } }
    ) {
      nodes {
        name
        enrollmentEnds
        ignitionBegins
        preflightBegins
        cohortBegins
        cohortEnds
      }
    }
    virtualCohorts: allStrapiVirtualCohort(
      sort: { order: ASC, fields: cohortBegins }
      filter: { showOnSite: { eq: true } }
    ) {
      nodes {
        name
        enrollmentEnds
        cohortBegins
        cohortEnds
      }
    }
    collaboratingBackground: file(
      relativePath: { regex: "/index-page/collaboratingBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          breakpoints: [210, 630]
          blurredOptions: { toFormat: WEBP }
          placeholder: BLURRED
        )
      }
    }
    iphoneScreenIndex: file(relativePath: { regex: "/bestQuestMobile.png/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 230
          aspectRatio: 0.5
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    iphoneScreenIndexSW: file(relativePath: { regex: "/sudokuMobile.png/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 230
          aspectRatio: 0.5
          transformOptions: { cropFocus: NORTH, fit: CONTAIN }
        )
      }
    }
    cirrLogo: file(relativePath: { regex: "/index-page/cirrLogo.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 250)
      }
    }
    stuffedAnimals: file(relativePath: { regex: "/stuffedAnimals/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 240)
      }
    }
    mockInterview: file(relativePath: { regex: "/mock-interview/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 460, height: 538)
      }
    }
    nickTeaching: file(relativePath: { regex: "/nickTeachingCode/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 400, height: 280)
      }
    }
    standup: file(relativePath: { regex: "/standup-video-screenshot/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          breakpoints: [300, 1200]
          transformOptions: { cropFocus: NORTH }
          blurredOptions: { toFormat: WEBP }
          backgroundColor: "#080808"
          placeholder: BLURRED
        )
      }
    }
    launcherSet: strapiFeaturedLauncherSet(name: { eq: "index" }) {
      id
      featured_launchers {
        id
        firstName
        currentJobTitle
        currentEmployer
        lastName
        headshot {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 300, height: 300)
            }
          }
        }
      }
    }
    hiringPartnerSet: strapiFeaturedHiringPartnerSet(name: { eq: "index" }) {
      featured_hiring_partners {
        companyName
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(breakpoints: [190], placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
