import Configuration from "../Configuration"
import { CourseScheduleProps } from "../components/courseSchedule/CourseSchedule.d"

interface Cohort {
  name: string
  enrollmentStarts?: string
  enrollmentEnds: string
  preflightBegins?: string
  ignitionBegins?: string
  cohortBegins: string
  cohortEnds: string
}

export const buildCohortProps = (jsonList: Cohort[]): CourseScheduleProps[] => {
  return jsonList.map((cohort) => {
    return {
      ...cohort,
      buttonURL: Configuration.applicationBaseUrl,
      id: cohort.name.replace("Boston ", ""),
      buttonText: "Apply Today",
    }
  })
}
