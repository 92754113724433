import React, { useMemo, useState } from "react"

import Modal from "../../modal/Modal"
import useModal from "../../modal/useModal"

import "../css/video.css"

const useVideoModal = ({ location, videoId, videoProvider, videoTitle }) => {
  const { isModalVisible, setModalVisibility } = useModal({ scrollToTop: false })

  const modal = useMemo(() => {
    return (
      <Modal
        size="large"
        isVisible={isModalVisible}
        hide={() => {
          setModalVisibility(false)
        }}
      >
        <div className="video-wrapper video-wrapper__16-9">
          <iframe
            width="100%"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            title={videoTitle || "Launch Academy Video"}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    )
  }, [videoId, videoTitle, setModalVisibility, isModalVisible])

  return {
    modal,
    isModalVisible,
    setModalVisibility,
  }
}

export default useVideoModal
